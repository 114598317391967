import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout";
import Helmet from "react-helmet"


const NotFoundPage = (props) => {

  //Header
  const $header = props.data.prismic.allNavigations.edges[0].node;

  //Footer
  const $footer = props.data.prismic.allFooters.edges[0].node;

  const $metadata = props.data.prismic.allHomes.edges[0].node;


  const $layoutData = { $header, $footer, $metadata };



  return (


    <Layout data={$layoutData} showCta={true} >

      <>
        <SEO metadata={$metadata}
          title="404" />

        <Helmet>

          <link rel="stylesheet" href="/css/pages/home/hero.css" />
          <link rel="stylesheet" href="/css/pages/404/404.css" />

        </Helmet>


        {/* HOME HERO */}
        <div className="c-home-hero o-align-middle js-header-waypoint" data-logo="dark" data-nav="light">{/*
    */}<div className="o-align-middle__aligner">
            <div className="c-home-hero__wrapper o-wrapper c-section">
              <div className="c-home-hero__holder">
                <span className="c-home-hero__caption">Error 404</span>
                <h2 className="c-home-hero__title">¡Ups!</h2>
                <p className="c-home-hero__text">No encontramos la página que estás buscando.</p>
                <div className="c-home-hero__btns">
                  <a href="/" className="c-home-hero__btn c-rounded-btn c-rounded-btn--border"><span>Ir a Inicio</span></a>
                </div> {/* .c-home-hero__btns */}
              </div> {/* .c-home-hero__holder */}
            </div> {/* .c-home-hero__wrapper */}
          </div> {/* .o-align-middle__aligner */}
          <div className="c-home-hero__decoration" />
        </div> {/* .c-home-hero */}

      </>


    </Layout>

  )

}

export const query = graphql`
query NotFoundPageQuery ($lang : String) {
  prismic {
    allNavigations (lang:$lang) {
            edges{
          node{
            ...HeaderFragment
          }
          }
        },
  allFooters (lang:$lang) {
            edges{
          node{
            ...FooterFragment
          }
          }
        },
   allHomes (lang:$lang)  {
            edges {
          node {
            ...MetadataFragment

          }
          }
        },
    }
    }
    `


export default NotFoundPage
